.btn {
  $this: &;

  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  padding: 5px 2px;
  margin-right: 0;
  margin-left: auto;

  &:focus {
    outline: none;
  }

  &__line{
    width: 100%;
    height: 2px;
    background: white;
  }

  &--close {
    #{ $this }__line {
      position: relative;
      &:first-child {
        top: 7px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        top: -8px;
        transform: rotate(-45deg);
      }
      &:last-child {
        display: none;
      }
    }
  }

  @media (min-width: 950px) {
    display: none;
  }
}