.hat-sizes {

  .paragraph {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 767px) {
    .table {
      td {
        padding: 10px 20px 10px 60%;
        &:before {
         content: 'Amžius';
        }
      
        &:nth-of-type(2) {
         &:before {
          content: 'Galvos apimtis';
         }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .table {
      margin-right: 0;
      margin-left: auto;
    }
  }
}