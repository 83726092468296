@import '../../styles/main';

.banner {
  background-image: url(../../img/children.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px;
  min-height: 50%;

  @media only screen and (min-width: 950px) {
    background-position: center -50px;
  }

  @media only screen and (min-width: 1200px) {
    background-position: center -90px;
    height: 500px;
  }
  @media only screen and (min-width: 1400px) {
    background-position: center -90px;
    height: 600px;
  }
}

.header-wrapper{
  padding-top: 20px;
}

.header {
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    padding: 20px;
    text-align: center;
    max-width: 1440px;
    margin: 0 auto;
  }

  &__h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }
}