h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $secondary-font;
  font-weight: 500;
  margin: 0 0 10px;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: $font-size-medium;
  }

  @media (min-width: 1200px) {
    font-size: $font-size-big;
  }
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0;
}
.paragraph {
  text-align: center;
}

a {
  color: inherit;
  text-decoration: initial;
}

img {
  width: 100%;
}