.shoes-sizes {
  margin-top: 40px;
  
  @media only screen and (max-width: 767px) {
    .table {
      td {
        padding: 10px 20px 10px 60%;
        &:before {
         content: 'Pėdos ilgis (cm)';
        }
      
        &:nth-of-type(2) {
         &:before {
          content: 'Dydis EU';
         }
        }

        &:nth-of-type(3) {
          &:before {
           content: 'Dydis UK';
          }
         }
      }
    }
  }
}