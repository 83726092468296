@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Raleway:400,500,600,700&display=swap');
@import 'styles/main';


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $main-font;
  color: $text-color-dark;

  .main {
    //padding: 0 20px;
  }

  .footer {
    margin-top: auto;
  }
}