// color variables

$color-1: #e5af5a;
$color-2: #888888;
$color-3: #c9da73;
$color-4: #E7B360;
$color-5: #e8e8e8;

$text-color-light-white: #fff;
$text-color-light: #656565;
$text-color-dark: #222222;

$bg-color-light: rgb(248, 248, 248);
$bg-color-dark: rgb(68, 68, 68);
$bg-color-darkness: #242424;

$table-border-color: #e0e0e0;


// font-family
$main-font: 'Raleway', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

// Headers
$h1-font-size: 26px;
$h2-font-size: 24px;
$h3-font-size: 22px;
$h4-font-size: 18px;
$h5-font-size: 17px;
$h6-font-size: 15px;


// font-size
$font-size-extra-small: 10px;
$font-size-small: 14px;
$font-size-medium: 26px;
$font-size-big: 32px;